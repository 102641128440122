import tyappRoute from './tyapp';
import error from './error';
import scanError from './scanError';
import turntable from './turntable';
import video from './video';

export default [
    tyappRoute,
    error,
    scanError,
    turntable,
    video
]