import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import auth from '@/auth'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faUserSecret, faQrcode, faLightbulb, faArrowRight, faCaretDown, faDeleteLeft, faXmark, faCircleCheck, faChevronLeft, faAngleRight, faTimes, faCirclePlus, faLocationCrosshairs, faMagnifyingGlass, faFloppyDisk, faEnvelope, faPhone, faIdCard, faCalendar, faMap, faVenusMars, faUser, faCheck, faArrowsRotate, faDiceD6, faChevronUp, faArrowRightFromBracket, faHeart, faCopy, faStar, faPlus, faBarcode, faRotateRight } from '@fortawesome/free-solid-svg-icons'
import { faImages, faCircleXmark, faHeart as faHeartR, faBell } from '@fortawesome/free-regular-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { firestorePlugin } from 'vuefire'
import './registerServiceWorker'

Vue.use(firestorePlugin)

import VueNotification from 'vue-notification'
Vue.use(VueNotification)

/* add icons to the library */
library.add(faUserSecret, faImages, faQrcode, faLightbulb, faArrowRight, faCaretDown, faDeleteLeft, faXmark, faCircleCheck, faChevronLeft, faAngleRight, faTimes, faCirclePlus, faCircleXmark, faLocationCrosshairs, faMagnifyingGlass, faFloppyDisk, faEnvelope, faPhone, faIdCard, faCalendar, faMap, faVenusMars, faUser, faCheck, faArrowsRotate, faDiceD6, faChevronUp, faArrowRightFromBracket, faBell, faHeart, faHeartR, faCopy, faStar, faPlus, faBarcode, faRotateRight)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.prototype.$http = qcsso.request;

auth(router);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
